import styled from '@emotion/styled';
import { prefix } from '@fortawesome/free-brands-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from './icon';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledSocialIcons = styled.div`
  display: flex;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      margin: 0.5rem;
      color: var(--title-color);
    }

    &:hover > svg {
      color: #fff;
    }
  }
`;

const StyledShop = styled.a`
  display: flex;
  padding-right: 0.5rem;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      margin: 0.5rem;
      color: var(--title-color);
    }

    &:hover > svg {
      color: #fff;
    }
  }
`;

const SocialIcons = ({ icons }) => {
  const socialIcons = icons.map(({ link, name, iconName, prefix }) => {
    return (
      <a href={link} key={link} rel="noopener" aria-label={`social link to ${link}`}>
        {name}
        <Icon icon={iconName} prefix={prefix} />
      </a>
    );
  });

  return (
    <StyledContainer>
      <StyledShop href='https://c27d9a.myshopify.com/' target="_blank" rel="noreferrer noopener">Shop <Icon icon={"shopping-cart"}/></StyledShop>
      <StyledSocialIcons>{socialIcons}</StyledSocialIcons>
    </StyledContainer>
  );
};

const iconShape = PropTypes.shape({
  link: PropTypes.string,
  icon: PropTypes.element,
});

SocialIcons.propTypes = {
  icons: PropTypes.arrayOf(iconShape),
};

export default SocialIcons;
